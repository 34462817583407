<template>
  <div>
    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="row mb-5">
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="item_id">{{ $t('product') }}</label>
              <div class="d-flex search-item-group">
                <select v-model="f_by" class="custom-select" name="" style="width: 100px;">
                  <option selected value="name">{{ $t('items.name') }}</option>
                  <option value="sku_code">{{ $t('items.sku_code') }}</option>
                </select>
                <multiselect id="item_id"
                             v-model="items_ids"
                             :internal-search="false"
                             :multiple="true"
                             :options="items"
                             :label="f_by?f_by:'sku_code'"
                             :placeholder="$t('product')"
                             @input="getIds('items')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             track-by="id"
                             @search-change="getItems(f_by, $event)">
                </multiselect>
              </div>
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="f_status">{{ $t('product_average_cost.inventory') }}</label>
              <!-- <select name="" id="f_status" v-model="filters.inventory_id" type="text" class="custom-select">
                  <option value="">{{$t('all')}}</option>
                  <option v-for="(row, index) in inventories" :value="row.id" :key="'inv'+index">{{ row.name }}</option>
              </select> -->
              <multiselect v-model="inventories_ids"
                           :placeholder="$t('product_average_cost.inventory')"
                           label="name"
                           track-by="id"
                           :options="inventories"
                           @input="getIds('inventories')"
                           :multiple="true"
                           :taggable="true"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false">
              </multiselect>
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_date">{{ $t('inventory_transaction_report.from_date') }}</label>
              <input type="date" id="from_date" v-model="filters.from_date" class="form-control">
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_date">{{ $t('inventory_transaction_report.to_date') }}</label>
              <input type="date" id="to_date" v-model="filters.to_date" class="form-control">
            </div>

            <div class="col-md-6 mt-2 mb-2 pt-10">
              <div>
                <b-button-group>
                  <b-button @click="changeIntervalType('current_day')" :variant="filters.period=='current_day'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_date') }}</b-button>
                  <b-button @click="changeIntervalType('current_month')" :variant="filters.period=='current_month'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_month') }}</b-button>
                  <b-button @click="changeIntervalType('current_year')" :variant="filters.period=='current_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.current_year') }}</b-button>
                  <b-button @click="changeIntervalType('financial_year')" :variant="filters.period=='financial_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.financial_year') }}</b-button>
                  <!--                    <b-button  @click="changeFinancialYear" :variant="filters.financial_year==1? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>-->
                </b-button-group>

              </div>
              <div class="mt-5">
                <b-button-group>
                  <b-button @click="changeIntervalType('yesterday')" :variant="filters.period=='yesterday'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.yesterday') }}</b-button>
                  <b-button @click="changeIntervalType('last_month')" :variant="filters.period=='last_month'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.last_month') }}</b-button>
                  <b-button @click="changeIntervalType('last_year')" :variant="filters.period=='last_year'? 'primary' : 'outline-primary'">{{ $t('customer_transactions_report.last_year') }}</b-button>
                </b-button-group>

              </div>
            </div>
            <!--                        <div class="form-group col-md-4 mt-2 mb-2">-->
            <!--                            <label for="product">{{$t('product')}}</label>-->
            <!--                            <multiselect id="product" v-model="item"-->
            <!--                                :placeholder="$t('product')"-->
            <!--                                label="name"-->
            <!--                                track-by="id"-->
            <!--                                :options="items"-->
            <!--                                :multiple="false"-->
            <!--                                :taggable="true"-->
            <!--                                :show-labels="false"-->
            <!--                                :show-no-options="false"-->
            <!--                                :show-no-results="false"-->
            <!--                                >-->
            <!--                            </multiselect>-->
            <!--                        </div>-->

            <!--                        <div class="form-group col-md-4 mt-2 mb-2">-->
            <!--                            <label>{{$t('items.category')}}</label>-->
            <!--                            <treeselect-->
            <!--                                    :options="categories"-->
            <!--                                    :load-options="loadOptions"-->
            <!--                                    :multiple="false"-->
            <!--                                    :value="filters.category_id"-->
            <!--                                    @input="updateValue"-->
            <!--                                    :searchable="true"-->
            <!--                                    :show-count="true"-->
            <!--                                    :no-children-text="$t('No_sub_options')"-->
            <!--                                    :no-options-text="$t('No_options_available')"-->
            <!--                                    :no-results-text="$t('No_results_found')"-->
            <!--                                    :placeholder="$t('Select')">-->
            <!--                            </treeselect>-->
            <!--                        </div>-->


            <div class="form-group d-inline-flex col-md-6 mt-10">
              <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"> {{ $t('display_report') }}</b-button>
              <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}</b-button>

            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <!--begin::customer-->
    <div class="card card-custom mt-5">

      <div class="card-body">

        <!-- Filter -->
        <div>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row">
              <!-- <div class="col-12 mt-10">
                  <hr>
              </div> -->
              <div class="col-12 mt-10" id="summaryTableTitle">
                <h4 class="text-center">{{ $t('product_average_cost.product_average_cost') }}</h4>
                <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }} {{ filters.from_date }}</span> <span v-if="filters.to_date">{{ $t('to') }} {{ filters.to_date }}</span></h5>
              </div>
              <div class="col-12 mt-10 d-flex justify-content-end">
                <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                  <!-- <download-excel
                          class="dropdown-item"
                          :fetch="dataReport"
                          :fields="json_fields"
                          :name="$t('estimated_inventory.estimated_inventory')+'.xls'">
                      <i class="la la-file-excel"></i>{{$t('excel')}}
                  </download-excel> -->
                  <button class="dropdown-item" @click="convertTableToExcelReport('summaryTable')">
                    <span><i class="fa fa-file-excel"></i> <span>{{ $t('excel') }}</span></span>
                  </button>

                  <button class="dropdown-item" @click="printData('summaryTable')">
                    <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                  </button>
                  <button class="dropdown-item" @click="getAllDataForExport('sectionForExport', 'excel')">
                    <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                  </button>
                </b-dropdown>
              </div>
              <div class="col-12 mt-10">
                <div class="table-responsive">
                  <table class="table table-bordered text-center" id="summaryTable">
                    <thead>
                    <tr>
                      <th rowspan="2">#</th>
                      <th rowspan="2">{{ $t('product_average_cost.code') }}</th>
                      <th rowspan="2">{{ $t('product_average_cost.barcode') }}</th>
                      <th rowspan="2">{{ $t('product_average_cost.product_name') }}</th>
                      <th colspan="1">{{ $t('product_average_cost.before') }}</th>
                      <th colspan="2">{{ $t('product_average_cost.incoming') }}</th>
                      <th colspan="2">{{ $t('product_average_cost.the_outgoing') }}</th>
                      <th colspan="2">{{ $t('product_average_cost.the_net') }}</th>
                    </tr>
                    <tr>
                      <td>{{ $t('quantity') }}</td>
<!--                      <td>{{ $t('amount') }}</td>-->
                      <td>{{ $t('quantity') }}</td>
                      <td>{{ $t('amount') }}</td>
                      <td>{{ $t('quantity') }}</td>
                      <td>{{ $t('amount') }}</td>
                      <td>{{ $t('quantity') }}</td>
                      <td>{{ $t('amount') }}</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row, index) in data.data" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ row.code }}</td>
                      <td>{{ row.barcode }}</td>
                      <td>{{ row.item_name }}</td>

                      <td>{{ row.total_before }}</td>
<!--                      <td>{{ row.before_total_amount }}</td>-->
                      <td>{{ row.inward_total }}</td>
                      <td>{{ row.inward_total_amount }}</td>
                      <td>{{ row.outward_total }}</td>
                      <td>{{ row.outward_total_amount }}</td>
                      <td>{{ row.total_transactions }}</td>
                      <td>{{ row.total_transactions_amount }}</td>
                    </tr>
                    </tbody>
                    <tbody>
                    <tr v-if="data.total">
                      <th colspan="4" style="text-align: start !important;">{{ $t('total') }}</th>
<!--                      <th></th>-->
                      <th>{{ data.total.sum_before_total }}</th>
                      <th></th>
                      <th>{{ data.total.sum_inward_total_amount }}</th>
                      <th></th>
                      <th>{{ data.total.sum_outward_total_amount }}</th>
                      <th></th>
                      <th>{{ data.total.sum_total_transactions_amount }}</th>
                    </tr>
                    </tbody>
                  </table>
                </div>

              </div>
              <div class="col-12 text-center" v-if="page">
                <button class="btn btn-warning" @click="loadMore">
                  <p class="mb-0">
                    <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                    {{ $t('load_more') }}
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- End Filter -->

      </div>
    </div>
    <!--end::customer-->

    <div class="export-container">
      <div class="section-for-export">
        <table class="table table-bordered text-center" id="sectionForExport">
          <thead>
          <tr>
            <th rowspan="2">#</th>
            <th rowspan="2">{{ $t('product_average_cost.code') }}</th>
            <th rowspan="2">{{ $t('product_average_cost.barcode') }}</th>
            <th rowspan="2">{{ $t('product_average_cost.product_name') }}</th>
            <th colspan="1">{{ $t('product_average_cost.before') }}</th>
            <th colspan="2">{{ $t('product_average_cost.incoming') }}</th>
            <th colspan="2">{{ $t('product_average_cost.the_outgoing') }}</th>
            <th colspan="2">{{ $t('product_average_cost.the_net') }}</th>
          </tr>
          <tr>
            <td>{{ $t('quantity') }}</td>
<!--            <td>{{ $t('amount') }}</td>-->
            <td>{{ $t('quantity') }}</td>
            <td>{{ $t('amount') }}</td>
            <td>{{ $t('quantity') }}</td>
            <td>{{ $t('amount') }}</td>
            <td>{{ $t('quantity') }}</td>
            <td>{{ $t('amount') }}</td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in dataDetailsList.data" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ row.code }}</td>
            <td>{{ row.barcode }}</td>
            <td>{{ row.item_name }}</td>

            <td>{{ row.total_before }}</td>
<!--            <td>{{ row.before_total_amount }}</td>-->
            <td>{{ row.inward_total }}</td>
            <td>{{ row.inward_total_amount }}</td>
            <td>{{ row.outward_total }}</td>
            <td>{{ row.outward_total_amount }}</td>
            <td>{{ row.total_transactions }}</td>
            <td>{{ row.total_transactions_amount }}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr v-if="dataDetailsList.total">
            <th colspan="4" style="text-align: start !important;">{{ $t('total') }}</th>
<!--            <th></th>-->
            <th>{{ dataDetailsList.total.sum_before_total }}</th>
            <th></th>
            <th>{{ dataDetailsList.total.sum_inward_total_amount }}</th>
            <th></th>
            <th>{{ dataDetailsList.total.sum_outward_total_amount }}</th>
            <th></th>
            <th>{{ dataDetailsList.total.sum_total_transactions_amount }}</th>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
        <!--        <report-main-header :print-type="'PurchaseBySupplierReport'"></report-main-header>-->
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import TableToExcel from "@linways/table-to-excel";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";

export default {
  name: "index-inventory-transaction-report",
  data() {
    return {
      mainRoute: '/reports/product-average-cost-report',
      mainRouteDependency: 'base/dependency',
      items: [],
      inventories: [],
      data: [],
      dataDetailsList: [],
      categories: [],

      filters: {
        category_id: null,
        item_id: null,
        inventory_id: null,
        f_items_ids: [],
        f_inventories_ids: [],
        from_date: null,
        to_date: null,

        period: null,
        range: null,
        f_time_period: 0,
        financial_year: 0,
      },
      item: null,
      inventory: null,
      items_ids: [],
      inventories_ids: [],
      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 0,
      f_by: 'name',
    }
  },
  computed: {

    // json_fields: function () {
    //     let fields = {};
    //     fields[this.$t('estimated_inventory.code')] = 'code';
    //     fields[this.$t('estimated_inventory.product_name')] = 'product_name';
    //     fields[this.$t('estimated_inventory.qty')] = 'qty';
    //     fields[this.$t('estimated_inventory.current_retail_price')] = 'current_retail_price';
    //     fields[this.$t('estimated_inventory.average_retail_price')] = 'average_retail_price';
    //     fields[this.$t('estimated_inventory.expected_total_retail_price')] = 'expected_total_retail_price';
    //     fields[this.$t('estimated_inventory.total_buy_price')] = 'total_buy_price';
    //     fields[this.$t('estimated_inventory.expected_profit')] = 'expected_profit';

    //     return fields;
    // },


  },
  // watch: {
  //   item: function (val) {
  //     if (val && val.id) {
  //       this.filters.item_id = val.id;
  //     } else {
  //       this.filters.item_id = null;
  //     }
  //   },
  //   inventory: function (val) {
  //     if (val && val.id) {
  //       this.filters.inventory_id = val.id;
  //     } else {
  //       this.filters.inventory_id = null;
  //     }
  //   }
  // },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.store_reports"), route: '/store/reports'}, {title: this.$t("MENU.product_average_cost")}]);

    // this.getCategories();
    this.getInventories();
  },
  methods: {
    ...cssStypeForPrintReport,

    convertTableToExcelReport(_tableId) {
      TableToExcel.convert(document.getElementById(_tableId), {
        name: this.$t('MENU.product_average_cost')+'.xlsx',
        sheet: {
          name: 'Sheet 1'
        }
      });
    },
    changeIntervalType(action_type, range = null){
      if (this.filters.period == action_type){
        this.filters.period = null;
        this.filters.f_time_period = 0;
      }else{
        this.filters.period = action_type;
        this.filters.f_time_period = 1;
      }

    },
    getIds(related_with) {

      switch (related_with) {
        case 'items':
          this.filters.f_items_ids = this.items_ids.map((row) => row.id);
          break;

        case 'inventories':
          this.filters.f_inventories_ids = this.inventories_ids.map((row) => row.id);
          break;
      }


    },
    doFilter() {
      this.page = 1;
      this.getData();
    },
    resetFilter() {

      this.filters.item_id = null;
      this.item = null;
      this.inventory = null;
      this.filters.category_id = null;
      this.filters.inventory_id = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.f_items_ids = [];
      this.filters.f_inventories_ids = [];
      this.items_ids = [];
      this.inventories_ids = [];

      this.filters.period = null;
      this.filters.range = null;
      this.filters.f_time_period = 0;
      this.filters.financial_year = 0;

      this.doFilter();

    },
    dataReport() {
      this.setReportLog('excel', 'product average cost');
      return this.data;
    },
    loadMore() {
      this.page = this.page ? (this.page + 1) : 1;
      this.getData();
    },
    getData() {
      let _limit = (this.page ? this.page : 1) * 15;
      ApiService.get(this.mainRoute, {params: {...this.filters, limit: _limit}}).then((response) => {
        this.data = response.data.data;
      });
    },
    getAllDataForExport(tableId, _export) {
      ApiService.get(this.mainRoute, {params: {...this.filters, is_export: 1}}).then((response) => {
        this.dataDetailsList = response.data.data;
        setTimeout(() => {
          this.convertTableToExcelReport(tableId);
        }, 100)
      });
    },
    updateValue(value) {
      this.filters.category_id = value
    },
    loadOptions() {
    },
    getCategories() {
      ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
        this.categories = response.data.data;
      });
    },
    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },
    printData(tableId) {
      this.setReportLog('pdf', 'product average cost');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
};
</script>
